import { HeadingText } from './HeadingText';
import { Text } from './Text';

const Stat = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className='rounded-md bg-zinc-700 p-3 text-white'>
      <Text>{title}</Text>
      <HeadingText className='font-bold md:text-xl'>{value}</HeadingText>
    </div>
  );
};

export { Stat };
