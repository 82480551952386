import { MdCalendarToday, MdLocationOn, MdOutbound } from 'react-icons/md';

import { EventCard, EventCardHeading } from '@/components/EventCard';
import { type TTransportEvent } from '@/types/models';

type EventsProps = {
  events: TTransportEvent[];
};

const Events = ({ events }: EventsProps) => {
  return events.length > 0 ? (
    <ul className='flex w-full flex-col gap-2'>
      <li className='hidden grid-cols-1 gap-2 rounded-lg bg-zinc-600 px-4 py-2 font-headings-medium text-lg text-white shadow-md md:grid md:grid-cols-3 md:justify-between'>
        <EventCardHeading icon={<MdOutbound color='white' />} label='Event' />
        <EventCardHeading
          icon={<MdCalendarToday color='white' />}
          label='Date & Time'
        />
        <EventCardHeading
          icon={<MdLocationOn color='white' />}
          label='Location'
        />
      </li>
      {events.map((transportEvent) => (
        <EventCard
          key={JSON.stringify(transportEvent)}
          transportEvent={transportEvent}
        />
      ))}
    </ul>
  ) : (
    <p className='text-lg md:text-xl'>No activity so far</p>
  );
};

export { Events };
