import { type ReactNode } from 'react';
import { MdCalendarToday, MdLocationOn, MdOutbound } from 'react-icons/md';

import { useEvent } from '@/hooks/useEvent';
import { type TTransportEvent } from '@/types/models';

type EventCardHeadingProps = {
  icon: ReactNode;
  label: string;
};

const EventCardValue = ({
  className,
  value,
}: {
  className?: string;
  value: string;
}) => {
  return (
    <p className={`text-right sm:text-xl md:text-left ${className}`}>{value}</p>
  );
};

const EventCardHeading = ({ icon, label }: EventCardHeadingProps) => {
  return (
    <div className='flex items-center gap-2'>
      {icon}
      <p className='font-headings-light text-base'>{label}</p>
    </div>
  );
};

type EventCardProps = {
  transportEvent: TTransportEvent;
};

const EventCard = ({ transportEvent }: EventCardProps) => {
  const { dateTime, location, name } = useEvent({ transportEvent });

  return (
    // <li className='grid grid-cols-2 gap-2 rounded-lg bg-zinc-500 p-4 font-headings-medium text-lg text-white shadow-md'>
    //   <div className='block'>
    //     <EventCardHeading icon={<MdOutbound color='white' />} label='Event' />
    //   </div>
    //   <EventCardValue className='font-content-bold' value={name} />
    //   <div className='block'>
    //     <EventCardHeading
    //       icon={<MdCalendarToday color='white' />}
    //       label='Date & Time'
    //     />
    //   </div>
    //   <EventCardValue className='font-content' value={dateTime} />
    //   <div className='block'>
    //     <EventCardHeading
    //       icon={<MdCalendarToday color='white' />}
    //       label='Location'
    //     />
    //   </div>
    //   <EventCardValue className='font-content' value={location.place} />
    // </li>
    <li className='grid grid-cols-2 gap-2 rounded-lg bg-zinc-500 p-4 font-headings-medium text-lg text-white shadow-md md:grid-cols-3 md:justify-between'>
      <div className='block md:hidden'>
        <EventCardHeading icon={<MdOutbound color='white' />} label='Event' />
      </div>
      <EventCardValue className='font-content-bold' value={name} />
      <div className='block md:hidden'>
        <EventCardHeading
          icon={<MdCalendarToday color='white' />}
          label='Date & Time'
        />
      </div>
      <EventCardValue className='font-content' value={dateTime} />
      <div className='block md:hidden'>
        <EventCardHeading
          icon={<MdLocationOn color='white' />}
          label='Location'
        />
      </div>
      <EventCardValue className='font-content' value={location} />
    </li>
  );
};

export { EventCard, EventCardHeading };
export type { EventCardProps, EventCardHeadingProps };
