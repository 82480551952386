import { useMemo } from 'react';

import { type TTransportEvent } from '@/types/models';
import { formatDateWithTime } from '@/utils/date';

type UseEventProps = { transportEvent: TTransportEvent };

const useEvent = ({ transportEvent }: UseEventProps) => {
  const dateTime = useMemo(
    () =>
      transportEvent?.createdAt
        ? formatDateWithTime(new Date(transportEvent.createdAt))
        : 'Unknown date',
    [transportEvent?.createdAt]
  );

  const name = useMemo(
    () => transportEvent?.event?.name ?? 'N/A',
    [transportEvent?.event?.name]
  );

  const location = useMemo(
    () => transportEvent?.location?.place ?? 'N/A',
    [transportEvent?.location]
  );

  return { dateTime, location, name };
};

export { useEvent };
