import { type ReactNode } from 'react';

type TTextProps = {
  children: ReactNode;
};

const Text = ({ children }: TTextProps) => {
  return <p className='text-lg'>{children}</p>;
};

export { Text };
export type { TTextProps };
